import Logo from "../images/Coachbyapp-Logo.png";
import React from "react";

const Header = ({ user }) => {
  return (
    <header className="header justify-between p-3">
      <div className="logo">
        <img src={Logo} alt="coachbyapp" width={150} />
      </div>
      <div className="profile text-right">
        <p className="font-semibold">
          {user?.firstName} {user?.lastName}
        </p>
        <p>{user?.emailAddress}</p>
        <p>{user?.phoneNumber}</p>
      </div>
    </header>
  );
};

export default Header;
